'use client';

import { useEffect } from 'react';

// this is the fallback for the root error boundary. in error case, it will be rendered in the root layout
// needs to be updated with a root fallback
const RootError = ({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  return (
    <div className="flex flex-col gap-2 p-2">
      <h2>Etwas ist schief gelaufen!</h2>
      <button onClick={() => reset()} type="button">
        Neu laden
      </button>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default RootError;
